var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vertical-menu"},[_c('div',{staticClass:"navbar-brand-box"},[_c('router-link',{staticClass:"logo logo-dark",attrs:{"to":"/"}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":_vm.side_bar_image_url,"alt":"","height":"25"}})]),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":_vm.side_bar_image_long_url,"alt":"","height":"50"}})])]),_c('router-link',{staticClass:"logo logo-light",attrs:{"to":"/"}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":_vm.side_bar_image_url,"alt":"","height":"25"}})]),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":_vm.side_bar_image_long_url,"alt":"","height":"50"}})])])],1),_c('button',{staticClass:"btn btn-sm px-3 font-size-16 header-item vertical-menu-btn",attrs:{"type":"button"},on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"fa fa-fw fa-bars"})]),_c('simplebar',{ref:"currentMenu",staticClass:"sidebar-menu-scroll"},[_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_vm._l((_vm.menuItems),function(item){return [(item.isTitle && _vm.menuToArray.includes(item.access))?_c('li',{key:item.id,staticClass:"menu-title"},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")]):_vm._e(),(
              !item.isTitle &&
                !item.isLayout &&
                _vm.menuToArray.includes(item.access)
            )?_c('li',{key:item.id},[(_vm.hasItems(item))?_c('a',{staticClass:"is-parent",class:{
                'has-arrow': !item.badge,
                'has-dropdown': item.badge,
              },attrs:{"href":"javascript:void(0);"}},[(item.icon)?_c('i',{class:("" + (item.icon))}):_vm._e(),(item.badge)?_c('span',{class:("badge rounded-pill bg-" + (item.badge.variant) + " float-end")},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t(item.label))+" "),_c('v-app',{staticClass:"menu-badge",staticStyle:{"background":"none !important"}},[(item.label === 'menuitems.customers.text' && _vm.totalRows > 0)?_c('v-badge',{staticStyle:{"background-color":"white"},attrs:{"color":"red","bordered":"","inline":"","content":_vm.totalRows}}):_vm._e()],1)],1)]):_vm._e(),(!_vm.hasItems(item))?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":item.link}},[(item.icon)?_c('i',{class:("" + (item.icon))}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t(item.label)))]),(item.badge)?_c('span',{class:("badge rounded-pill bg-" + (item.badge.variant) + " float-end")},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),(_vm.hasItems(item))?_c('ul',{staticClass:"sub-menu",attrs:{"aria-expanded":"false"}},_vm._l((item.subItems),function(subitem,index){return _c('li',{key:index},[(
                    !_vm.hasItems(subitem) && _vm.menuToArray.includes(subitem.access)
                  )?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":subitem.link}},[_vm._v(_vm._s(_vm.$t(subitem.label))+" "),(
                      subitem.access === 'customers_requests' && _vm.deleteUserRows > 0
                    )?_c('v-app',{staticStyle:{"float":"right","background":"none !important"}},[_c('v-badge',{staticStyle:{"background-color":"white"},attrs:{"color":"red","overlap":"","bordered":"","inline":"","content":_vm.deleteUserRows}})],1):_vm._e(),(
                      subitem.access === 'blocked_users' && _vm.blockedUserRows > 0
                    )?_c('v-app',{staticStyle:{"float":"right","background":"none !important"}},[_c('v-badge',{staticStyle:{"background-color":"white"},attrs:{"color":"red","overlap":"","bordered":"","inline":"","content":_vm.blockedUserRows}})],1):_vm._e()],1):_vm._e(),(_vm.hasItems(subitem))?_c('a',{staticClass:"side-nav-link-a-ref has-arrow",attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(_vm.$t(subitem.label))+" ")]):_vm._e(),(_vm.hasItems(subitem))?_c('ul',{staticClass:"sub-menu mm-collapse",attrs:{"aria-expanded":"false"}},_vm._l((subitem.subItems),function(subSubitem,index){return _c('li',{key:index},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":subSubitem.link}},[_vm._v(_vm._s(_vm.$t(subSubitem.label)))])],1)}),0):_vm._e()],1)}),0):_vm._e()],1):_vm._e()]})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }